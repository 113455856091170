import * as React from "react"
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout"
import { css, Themed } from "theme-ui"
import SEO from "gatsby-theme-blog/src/components/seo"
import Footer from "gatsby-theme-blog/src/components/home-footer"
import PostList from "gatsby-theme-blog/src/components/post-list"

const Posts = ({ location, posts, siteTitle, socialLinks }) => (
    <Layout color={undefined}>
        {/* <SEO title="Home" /> */}
        {/* <main> */}
        <div
            css={css({
                maxWidth: `container`,
                mx: `auto`,
                px: 3,
                py: 4,
            })}
        >

            {posts.length > 0 ? <PostList posts={posts} /> : <h2> Per ora ancora nessun post ...</h2>}
            {/* </main> */}
            <Footer socialLinks={socialLinks} />
        </div>

    </Layout>
)

export default Posts
